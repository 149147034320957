<template>
  <el-row class="table">
    <span class="title">数据同步</span>
    <el-row style="margin-top: 20px;" :gutter="20">
      <el-col :xs="24" :sm="12" :md="12" :lg="10" :xl="6" class="tb_col">
        <el-card shadow="hover">
          <el-form ref="LessonForm" :model="LessonForm" label-width="80px">
            <el-form-item label="起始日期">
              <el-date-picker v-model="LessonForm.startDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期">
              <el-date-picker v-model="LessonForm.endDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            @click="LessonSave"
            style="margin-left: 80px;margin-top :15px;"
          >一键同步排课</el-button>
        </el-card>
      </el-col>

      <el-col :xs="24" :sm="12" :md="12" :lg="10" :xl="6" class="tb_col">
        <el-card shadow="hover">
          <el-form ref="ClassForm" :model="ClassForm" label-width="80px">
            <el-form-item label="起始日期">
              <el-date-picker v-model="ClassForm.startDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期">
              <el-date-picker v-model="ClassForm.endDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            @click="ClassSave"
            style="margin-left: 80px;margin-top :15px;"
          >一键同步班级</el-button>
        </el-card>
      </el-col>

      <el-col :xs="24" :sm="12" :md="12" :lg="10" :xl="6" class="tb_col">
        <el-card shadow="hover">
          <el-form ref="UserForm" :model="UserForm" label-width="80px">
            <el-form-item label="起始日期">
              <el-date-picker v-model="UserForm.date" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            @click="UserSave"
            style="margin-left: 80px;margin-top :15px;"
          >一键同步用户</el-button>
        </el-card>
      </el-col>

      <el-col :xs="24" :sm="12" :md="12" :lg="10" :xl="6" class="tb_col">
        <el-card shadow="hover">
          <el-form ref="TryForm" :model="UserForm" label-width="80px">
            <el-form-item label="起始日期">
              <el-date-picker v-model="TryForm.date" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="TrySave" style="margin-left: 80px;margin-top :15px;">一键同步试听学员</el-button>
        </el-card>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { getXgjUsers, getXgjLessonBat, getXgjClasses, getXgjTryStudent } from '@/api/system/sync'

export default {
  data () {
    return {
      UserForm: {
        date: new Date()
      },
      LessonForm: {
        startDate: null,
        endDate: null
      },
      ClassForm: {
        startDate: null,
        endDate: null
      },
      TryForm: {
        date: new Date()
      }
    }
  },
  methods: {
    // 同步时间 格式化
    dateFormt (date) {
      let newYear = parseInt(date.getFullYear()) // 获取当前时间的年
      let newMonth = parseInt(date.getMonth()) + 1 // 获取当前时间的月
      let newDay = parseInt(date.getDate()) // 获取当前天
      let res = ''
      if (newMonth < 10) {
        newMonth = '0' + newMonth
      }
      if (newDay < 10) {
        newDay = '0' + newDay
      }
      res = `${newYear}${newMonth}${newDay}`
      return res
    },

    // 一键同步用户
    UserSave () {
      getXgjUsers({
        minUpdateTime: this.dateFormt(this.UserForm.date)
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('同步成功')
          console.log('同步用户', res.body)
        }
      })
    },

    // 一键同步排课
    LessonSave () {
      getXgjLessonBat({
        minUpdateTime: this.dateFormt(this.LessonForm.startDate),
        maxUpdateTime: this.dateFormt(this.LessonForm.endDate)
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('同步成功')
          console.log('同步排课', res.body)
        }
      })
    },

    // 一键同步班级
    ClassSave () {
      getXgjClasses({
        minUpdateTime: this.dateFormt(this.ClassForm.startDate),
        maxUpdateTime: this.dateFormt(this.ClassForm.endDate)
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('同步成功')
          console.log('同步班级', res.body)
        }
      })
    },

    // 一键同步试听学员
    TrySave () {
      getXgjTryStudent({
        minUpdateTime: this.dateFormt(this.TryForm.date)
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('同步成功')
          console.log('同步试听学员', res.body)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";

.tb_col {
  margin-bottom: 50px;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}
</style>
